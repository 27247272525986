import type { RedirectFunction } from "@remix-run/server-runtime";
import type { TypedJsonResponse } from "remix-typedjson";
import type { AppLoadContext } from "~/remix";
import { defer, json, redirect, } from "~/remix";
import { sendActionToGM, sendActionToPlayers } from "~/socket/klient-on-zerver-side";
import { emitter } from "./emitter.server";

type ReturnArgType<T> = T extends Array<string | number> ? T[number] : T;

export default class _ {

  // static random<ST extends string | number, T extends ST | Array<ST> | Array<any>(object: T, max = 0): string | number | any {
  static random<T extends string | number | Array<any>>(object: T, max = 0): T extends (infer E)[] ? E : T extends string ? string : number {
    if (typeof object === 'string') {
      // @ts-ignore
      return this.random(String(object).split('||'), max);
    } else if (object instanceof Map) {
      return this.random(Array.from(object.values()), max);
    } else if (object instanceof Array) {
      return object[Math.floor((Math.random() * object.length))];
    } else if (Number.isInteger(object)) {
      const num: number = object as unknown as number;
      // @ts-ignore
      return Math.floor(Math.random() * (max - num + 1) + num);
    }
    //@ts-ignore
    return null;
  }

  static rand<T extends string | number | Array<any>>(object: T, max = 0): T extends (infer E)[] ? E : T extends string ? string : number {
    return this.random(object, max);
  }

  static sortAny(a: any, b: any, invert = false) {
    if (a === b) return 0;
    return (a > b ? 1 : -1) * (invert ? -1 : 1)
  }

  static sortAlpha(a: string, b: string, invert = false) {
    return this.sortAny(a, b, invert);
  }

  static sortAlphaI(a: string, b: string, invert = false) {
    return this.sortAlpha(a.toLowerCase(), b.toLowerCase(), invert);
  }

  static sortDate(a: Date, b: Date, invert = false) {
    if (a === b) return 0;
    return this.sortAny(a, b, invert);
  }

  static sortDateI(a: Date, b: Date, invert = false) {
    return this.sortDate(a, b, invert);
  }

  static sortInt(a: string | number, b: string | number, invert = false) {

    let iA: number;
    let iB: number;

    iA = typeof a === "string" ? parseInt(a, 10) : a;
    iB = typeof b === "string" ? parseInt(b, 10) : b;
    return this.sortAny(iA, iB, invert);
  }

  static sortIntDesc(a: number, b: number) {
    return this.sortInt(a, b, true);
  }

  static isClass(object: any) {
    return typeof object === 'function'
      && /^class\s/.test(Function.prototype.toString.call(object));
  }

  static noop() { }

  // static dateToSQL(date: moment.MomentInput) {
  //   let ret = moment.utc(date).format().slice(0, 19).replace('T', ' ');
  //   return ret;
  // }

  /** Function that count occurrences of a substring in a string;
   * @param {String} string               The string
   * @param {String} subString            The sub string to search for
   * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
   *
   * @author Vitim.us https://gist.github.com/victornpb/7736865
   * @see Unit Test https://jsfiddle.net/Victornpb/5axuh96u/
   * @see http://stackoverflow.com/questions/4009756/how-to-count-string-occurrence-in-string/7924240#7924240
   */
  static strcount(string: string, subString: string, allowOverlapping: boolean = false) {

    string += "";
    subString += "";
    if (subString.length <= 0) return (string.length + 1);

    var n = 0,
      pos = 0,
      step = allowOverlapping ? 1 : subString.length;

    while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
        ++n;
        pos += step;
      } else break;
    }
    return n;
  }

  static regExpEscape(s: string) {
    return s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  // static klawFileSorter = (item1: Item, item2: Item) => {
  //   let ret = item1.stats.mtimeMs - item2.stats.mtimeMs;
  //   return ret;
  // }

  static wait(ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  static contains(haystack: string | null, needle: string | RegExp) {
    if (!haystack) return false;
    let st = typeof needle === 'string' ? haystack.includes(needle) : needle.test(haystack);
    // if (!st && typeof needle === 'string') {
    //   // With emoji sometimes includes fails
    //   st = haystack.split('').some(car => {
    //     return car.charCodeAt(0) === needle.charCodeAt(0)
    //   });
    // }
    return st;
  }

  static isDev() {
    return process.env.NODE_ENV === "development";
  }

  static shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length;
    let randomIndex: number;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  static nbsp = String.fromCharCode(160);
  static now() {
    return new Date()
  }

  static nowIso() {
    return (new Date()).toISOString();
  }
  static nowInt() {
    return Date.now()
  }

  static xMillisecondsAgoInt(x = 1) {
    return Date.now() - x;
  }

  static xMillisecondsAgo(x = 1) {
    return new Date(this.xMillisecondsAgoInt(x));
  }

  static xSecondsAgoInt(x = 1) {
    return this.xMillisecondsAgoInt(x * 1000);
  }

  static xSecondsAgo(x = 1) {
    return new Date(this.xSecondsAgoInt(x));
  }


  static xMinutesAgoInt(x = 1) {
    return this.xSecondsAgo(60 * x);
  }

  static xMinutesAgo(x = 1) {
    return new Date(this.xMinutesAgoInt(x));
  }

}

export const className = (...classNames: Array<React.HTMLAttributes<HTMLElement>['className'] | undefined | null>) => classNames.filter(c => typeof c === 'string').join(' ').trim();

export const sleep = (ms: number, cb = () => { }) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  }).then(cb);
}

type RefreshContextPlayerMaster = {
  slug: string
  context: AppLoadContext
  action?: string
  userIds?: never
  playerIds?: never
} & ({
  scope: "player"
  playerId: number
} | {
  scope: "master"
  playerId?: never
})

type _RefreshContextPlayers = {
  scope: "players" | "player"
  slug: string
  // context: AppLoadContext
  action?: string
}

type RCP1 = _RefreshContextPlayers & {
  scope: "players"
  all: true
  playerIds?: never
}

type RCP2 = _RefreshContextPlayers & {
  scope: "players"
  all?: false
  playerIds: number[]
}

type RCP3 = Omit<_RefreshContextPlayers, "scope"> & {
  scope: "player"
  playerId: number
}

type RefreshContextPlayers = RCP1 | RCP2 | RCP3

type RefreshContext = RefreshContextPlayerMaster | RefreshContextPlayers
export const redirectAndRefresh = (refreshContext: RefreshContext, ...redirectArgs: Parameters<RedirectFunction>) => {
  const {
    scope,
    slug,
    action = 'refresh',
    userIds = undefined,
    playerId = undefined,
    playerIds = undefined,
  } = { ...refreshContext };

  switch (scope) {
    case "master":
      sendActionToGM({
        // @ts-ignore
        action,
        slug,
      });
      emitter.sendEventToGM(slug, {
        // @ts-ignore
        action,
        slug,
      });
      // sendActionToGM({
      //   // @ts-ignore
      //   action,
      //   slug,
      // })
      break;
    case 'player':
      sendActionToPlayers({
        // @ts-expect-error
        action,
      });
      emitter.sendEventToPlayer(slug, {
        action: 'refresh',
        playerId: Number(playerId),
      });
      break;
    case 'players':
      sendActionToPlayers({
        // @ts-expect-error
        action,
        userIds,
      });
      emitter.sendEventToPlayers(slug, {
        // @ts-expect-error
        action,
        playerIds,
      });

      // sendActionToPlayers({
      //   action,
      //   slug,
      //   userIds,
      // })
      break;
  }

  return redirect(...redirectArgs)
}

export const deferAndRefresh = <Data>(refreshContext: RefreshContext, data: Data & {
  redrirectTo?: string
  forceMasterRefresh?: boolean
}, init?: number | ResponseInit) => {
  return jsonAndRefresh(refreshContext, data, init, defer)
}

export const jsonAndRefresh = <Data>(refreshContext: RefreshContext, data: Data & {
  redrirectTo?: string
  forceMasterRefresh?: boolean
}, init?: number | ResponseInit, sender = json): TypedJsonResponse<Data> => {

  const { scope,
    slug,
    action = 'refresh',
    playerIds = undefined,
    playerId = undefined,
    all = false,
  } = { ...refreshContext };

  if (scope === "master") {
    sendActionToGM({
      // @ts-expect-error
      action,
    });
    emitter.sendEventToGM(slug, {
      // @ts-expect-error
      action,
    });
  } else if (scope === 'player') {
    sendActionToPlayers({
      // @ts-expect-error
      action,
    });
    emitter.sendEventToPlayer(slug, {
      scope: 'player',
      // @ts-expect-error
      action,
      // @ts-expect-error
      playerId,
    });
  } else if (scope === 'players') {
    sendActionToPlayers({
      // @ts-expect-error
      action,
      all,
      playerIds,
    });
    emitter.sendEventToPlayers(slug, {
      // @ts-expect-error
      action,
      all,
      playerIds,
    });
  }

  if (typeof data === 'object') {
    // const triggerRefreshOld = Buffer.from(Date.now().toString()).toString('base64');
    return sender({
      // triggerRefreshOld,
      ...data,
    }, init)
  }
  return sender(data, init)
}

export function noop() { }

export function isSentryEnable() {
  let DNS = '';
  let _NODE_ENV: typeof ENV.MODE = 'development';
  try {

    if (ENV && typeof ENV === 'object') {
      DNS = ENV.SENTRY_DSN ?? '';
      _NODE_ENV = ENV.MODE;
    }

    if (process && typeof process === 'object') {
      DNS = process.env.SENTRY_DSN ?? '';
      _NODE_ENV = process.env.NODE_ENV;
    }
  } catch (e) {

  }

  return _NODE_ENV === 'production' && !DNS.startsWith('#');
}